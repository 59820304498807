<template>
  <Classe />
</template>

<script>
import Classe from "@/components/gestao/Classe.vue";

export default {
  components: {
    Classe
  }
};
</script>
